// src/context/LogContext.js
import React, { createContext, useState } from 'react';

export const LogContext = createContext();

export const LoginProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState(localStorage.getItem('userName') || '');

  const login = () => {
    const storedName = localStorage.getItem('userName');
    console.log("User Name from LocalStorage:", storedName); 
    if (storedName) setUserName(storedName);
    setIsLoggedIn(true);
   
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUserName('');
    localStorage.removeItem('userName'); 
  };

  return (
    <LogContext.Provider value={{ isLoggedIn, userName, login, logout }}>
      {children}
    </LogContext.Provider>
  );
};
