import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Import icons from react-icons
import "./SessionSlider.scss";

import session2 from '../asset/icons/session2.jpeg';
import session3 from '../asset/icons/session3.jpeg';
import session4 from '../asset/icons/session4.jpeg';
import session5 from '../asset/icons/session5.jpeg';

const employees = [
  { id: 2, image: session2 },
  { id: 3, image: session3 },
  { id: 4, image: session4 },
  { id: 5, image: session5 },
  { id: 6, image: session3 },
  { id: 7, image: session4 },
  { id: 8, image: session5 },
];

const SessionSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the next slide
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === employees.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to go to the previous slide
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? employees.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="slider-container">
      <motion.div
        className="slider-row"
        initial={{ x: 0 }}
        animate={{ x: -currentIndex * 300 }} // Adjust based on the width of each slide
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        {employees.map((employee, index) => (
          <motion.div key={index} className="employee-cards">
            <img className="slide-image" src={employee.image} alt={`Session ${employee.id}`} />
          </motion.div>
        ))}
      </motion.div>

      {/* Left arrow icon */}
      <div className="icon-container left" onClick={handlePrev}>
        <FaChevronLeft className="icon" />
      </div>

      {/* Right arrow icon */}
      <div className="icon-container right" onClick={handleNext}>
        <FaChevronRight className="icon" />
      </div>
    </div>
  );
};

export default SessionSlider;
