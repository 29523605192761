
import './App.css';

import Homepage from './Components/Homepage';

import { LoginProvider } from './context/LogContext';
function App() {
  return (
    <LoginProvider>
      <div className="App">

<Homepage/>

    </div>
    </LoginProvider>
  );
}

export default App;
