import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Signup.scss';
import axios from 'axios';

function Signup() {
   
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    // Individual required field error states
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setNameError('');
        setEmailError('');
        setPasswordError('');

       
        let isValid = true;

        // Check each field and set error messages as needed
        if (!name) {
            setNameError('This field is required');
            isValid = false;
        }
        if (!email) {
            setEmailError('This field is required');
            isValid = false;
        }
        if (!password) {
            setPasswordError('This field is required');
            isValid = false;
        }

        // Only proceed if all fields are filled
        if (!isValid) return;

        try {
            const response = await axios.post('https://thecodingshef-be.vercel.app/auth/signup', {
                email,
                password,
                name
            });

            // console.log(response.data);
            
            
            if (response.data) {
                setShowPopup(true); // Show success popup
                setTimeout(() => {
                    setShowPopup(false); 
                    navigate('/login'); // Redirect to login after 2 seconds
                }, 2000);
            }
        } catch (err) {
            setError('Signup failed. Please try again.');
        }
    };

    return (
        <div className='loginform-section'>
            <div className="login-form">
                <div className="login-container">
                    <div className="login-fields">
                        <h3 className="form-heading">Signup</h3>
                        <div className="form-fields">
                            <form onSubmit={handleSubmit}>
                                <div className="name">
                                    <p>Name<sup style={{color:"red"}}>*</sup></p>
                                    <input type="text" value={name} placeholder="Enter your name" className="form" onChange={(e) => setName(e.target.value)} />
                                    {nameError && <p className="error-message">{nameError}</p>}
                                </div>
                                <div className="signup-email">
                                    <p>Email<sup style={{color:"red"}}>*</sup></p>
                                    <input type="email" value={email} placeholder="Enter your email" className="form" onChange={(e) => setEmail(e.target.value)} />
                                    {emailError && <p className="error-message">{emailError}</p>}
                                </div>
                                <div className="password">
                                    <p>Password<sup style={{color:"red"}}>*</sup></p>
                                    <input type="password" value={password} placeholder="Enter your password" className="form" onChange={(e) => setPassword(e.target.value)} />
                                    {passwordError && <p className="error-message">{passwordError}</p>}
                                </div>
<div className="account">
  <p className='account-txt'>Already have an account</p> <Link to="/login" style={{textDecoration:"none",color:"black",}}><p className='account-txt-login'>Login</p></Link>
</div>
                                <div className="button">
                                    <button type="submit" className="login-btn">Sign up</button>
                                </div>

                                {error && <p className="last-error-message">{error}</p>}
                            </form>
                           
                        </div>
                    </div>
                </div>
            </div>

            {/* Popup for successful signup */}
            {showPopup && (
                <div className="popup">
                    <p>Signup successful! Redirecting to login...</p>
                </div>
            )}
        </div>
    );
}

export default Signup;
