import React, { useState } from "react";
import "./Main.scss";
import {  RiCloseLine } from "react-icons/ri";

import { motion, AnimatePresence } from 'framer-motion';
import { RiArrowDropDownLine } from "react-icons/ri";
import Banner from '../asset/icons/Banner.jpeg'
import './Homepage.scss'

import { FaBookOpen } from "react-icons/fa6";
import { BsPersonVideo } from "react-icons/bs";

import { RiPagesLine } from "react-icons/ri";
import { CiVideoOn } from "react-icons/ci";
import { MdOutlineStickyNote2 } from "react-icons/md";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { FaTv } from "react-icons/fa6";
import { FaConnectdevelop } from "react-icons/fa6";
import { FaGift } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { FaGithub } from "react-icons/fa";
import { MdOutlineBrandingWatermark } from "react-icons/md";
import { FaAddressCard } from "react-icons/fa";
import ProjectSlider from "./ProjectSlider";

import { AiOutlineSend } from "react-icons/ai";
import { Link } from "react-router-dom";
export default function Main() {

const data=[
    {
        skill:"HTML",
      
    },
    {
        skill:"CSS",
       

    },
    {
        skill:"Javascript",
        
    },
    {
        skill:"React",
        
    },
    {
        skill:"Typescript",
        
    },
   
    {
        skill:"Bootstrap"
    },
    {
        skill:"Material Ui"
    },
    {
        skill:"Git /Github"
    },
    {
        skill:"DSA in JS"
    },
    {
        skill:"Interview Questions"
    },

    {
        skill:"System Design"
    },
    
    {
        skill:"Firebase "
    },
    {
        skill:"Tailwind CSS"
    },
   
   
]




const[skill ,setSkill]=useState(data)

//  toggle

const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
  
    if (openQuestion === index) {
      setOpenQuestion(null);
    } else {
      setOpenQuestion(index);
    }
  };

  
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const[isHelp ,setIsHelp]=useState(false)
  
  const toggleTermsModal = () => {
    setIsTermsOpen(!isTermsOpen);
   
// setIsHelp(!isHelp)
  };


  const togglePrivacyModel=()=>{
    setIsPrivacy(!isPrivacy)
  }


  const togglecontactModel=()=>{
    setIsHelp(!isHelp)
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="container">
     {/* <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} togglecontactModel={togglecontactModel} /> */}
      {/* navbar */}

      <div className="navbar">
        <div className="nav-logo"><Link style={{textDecoration:"none" ,color:"orange"}} to="/home">TheCodingshef</Link></div>
        <div className="nav-items">
          <li className="list-item"><Link style={{textDecoration:"none" ,color:"orange" , fontWeight:"500"}} to="/home">Home</Link></li>
          <li className="list-item" onClick={togglecontactModel}>Contact</li>
          {/* <button className="sidebar-toggle" onClick={toggleSidebar}>
            {isSidebarOpen ? <IoClose className='close' /> : <FaBars className='open' />}
          </button> */}
         
        </div>
      </div>

      {/* frontpart */}

      <div className="front-section">
        <div className="frontpart">
          {/*  front right part */}

          <div className="right-front">
            <p className="front-heading">6 Month To Launch Your Career 🔥</p>

            <h1 className="front-main__heading">
              WebShef <span style={{ color: "orange" }}>1.0</span>
            </h1>

            <p className="front-last__heading">
              The Most Affordable Programme For Front-End Mastery
            </p>
<p className="front-mini-heading">3 Month Extensive Training + 3 Month Internship</p>

            <div className="time-grid">
<div className="from-time__grid"><div className="icon"><CiVideoOn  className="time-icon"/></div><p>Live Classes</p></div>
<div className="from-time__grid-seclast"><div className="icon"><RiPagesLine  className="time-icon2"/></div><p>Projects</p></div>
<div className="from-time__grid"><div className="icon"><BsPersonVideo  className="time-icon3"/></div><p>Doubt classes</p></div>
<div className="from-time__grid-last"><div className="icon"><FaBookOpen className="time-icon4" /></div><p>Assignment</p></div>
            </div>
          </div>

          {/*  front left part */}
          <div className="left-front">
            <div className="left-front__card">
              <div className="card-first__section">
                <img src={Banner} alt="" className="image" />
              </div>

              <div className="card-second__section">
                <h3 className="card-second__heading">
                  Complete Frontend Development Course (Projects + Roadmap){" "}
                </h3>
                <div className="card-price__section">
                  <div className="price">
                    <div className="current">₹3,499</div>
                    <div className="prev-price">₹6,999</div>
                  </div>
                
                </div>
                 <a href="https://pages.razorpay.com/webshef1" ><button 
     className="card-buy__course-btn">Buy Course</button></a>
                <div className="course-include">
                <h3 style={{color: "#666"}}>COURSE INCLUDES :</h3>
<div className="point"><AiOutlineSend  className="point-icon"/><p>No prerequisite Required</p></div>

<div className="point"><AiOutlineSend  className="point-icon"/><p> Best Projects</p></div>
<div className="point"><AiOutlineSend  className="point-icon"/><p>Alternate days classes</p></div>
<div className="point"><AiOutlineSend  className="point-icon"/><p>Sunday Doubt classes</p></div>
<div className="point"><AiOutlineSend  className="point-icon"/><p>Live Sessions</p></div>
                 
         
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* secons section */}

      <div className="learn-section">
        <div className="learn">
        {/* first div */}
            <div className="learn-first__part">
<h1 className="learn-first__heading">What you will learn</h1>
<h3 className="learn-second__heading">Bhout Kuch Sikha Denge 🚀 </h3>
            </div>

{/* second div */}
<div className="learn-grid">

{/*  ist skill */}


{
    skill.map((ele)=>{
        return(
            <>
            <div className="learn-items">
    <div className="mark"></div> 
    <div className="learn-skill">
        <p className="skill">{ele.skill}</p>
    </div>
    </div>
            </>
        )
    })
}
    
</div>
        </div>
      </div>

      {/*  third section perks and benefits */}

      <div className="perks-section">
      <div className="perk-benefits">

      <div className="perks">
            <h1 className="perks-heading">Perks & Benefits</h1>
        </div>

        <div className="perks-grid">

<div className="perks-div">
<div className="perk-icon"><AiOutlineFundProjectionScreen className="officon"/></div>
<div className="perk-text">Project Based Learning</div>
</div>
<div className="perks-div">
<div className="perk-icon"><MdOutlineStickyNote2 className="officon"/></div>
<div className="perk-text">Assignments </div>
</div>
<div className="perks-div">
<div className="perk-icon"><FaTv className="officon"/></div>
<div className="perk-text">Sunday Doubt Classes</div>
</div>
<div className="perks-div">
<div className="perk-icon"><FaConnectdevelop  className="officon"/></div>
<div className="perk-text">Learning Basic to Advance</div>
</div>
<div className="perks-div"><div className="perk-icon"><AiFillSafetyCertificate className="officon"/></div>
<div className="perk-text">Certificates</div></div>
<div className="perks-div"><div className="perk-icon"><FaGift  className="officon"/></div>
<div className="perk-text">Goodies</div></div>
<div className="perks-div"><div className="perk-icon"><FaMoneyBillWave  className="officon"/></div>
<div className="perk-text">Internship </div></div>
<div className="perks-div"><div className="perk-icon"><FaGithub  className="officon"/></div>
<div className="perk-text">Bonus Session on Open Source</div></div>
<div className="perks-div"><div className="perk-icon"><MdOutlineBrandingWatermark  className="officon"/></div>
<div className="perk-text">Bonus Session on Personal Branding</div></div>

<div className="perks-div"><div className="perk-icon"><FaLinkedin  className="officon"/></div>
<div className="perk-text">Bonus Session on Linkedin profile</div></div>

<div className="perks-div"><div className="perk-icon"><FaAddressCard  className="officon" /></div>
<div className="perk-text">Bonus Session on Resume Building</div></div>
        </div>
      </div>


      </div>




      {/*  forth section */}
<div className="project-section">
<div className="project">
<div className="project-heading"><h1 className="heading">Awesome Projects cover in this course </h1></div>


<div className="slide-section">
      {/* <EmployeeSlider/> */}
      <ProjectSlider/>
    </div>
       
        {/* <div className="project-grid">
    <div className="card-div"><img  className="project-card" src={portfolio} alt="" />
    <h3 className="pro-heading"> Apna Portfoliyo</h3></div>
    <div className="card-div"><img className="project-card"  src={pick} alt="" /><h3 className="pro-heading">Pick & Drop service App</h3></div>
    <div className="card-div"><img  className="project-card" src={ecommerse} alt="" /><h3 className="pro-heading">E-commerse Website</h3></div>
   
   
    <div className="card-div"><img  className="project-card" src={food} alt="" /><h3 className="pro-heading"> Online Food Ordering App</h3></div>
    
        </div> */}


{/*  Project information */}


    
   
    
</div>
</div>





{/*  Demo vedio section */}

<div className="demo-vedio__section">
  <div className="vedio-section">
    <div className="demo-heading"><h1 className="heading">Sample Lectures </h1></div>

    <div className="demo-vedio">
    <iframe  src="https://www.youtube.com/embed/yl0BFo7fN_c?si=AYgyNs_3K3FOcN3a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    <a href="https://www.youtube.com/@TheCodingShef" style={{ textDecoration:"none"}}><div className="button"><button className="youtube-btn">Checkout on Youtube</button></div></a>
    </div>

  </div>
</div>

{/* Last section Faq  section */}

<div className="question-section">
    <div className="question" >
        <div className="question-heading">
            <h1 className="heading" style={{marginTop:"8rem"}}>FAQ Questions</h1>
        </div>

        {/*  questions list */}
     
        <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(0)} className="faq-question">
         <h3 className="react-heading"> Will react be coverd in this course</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown" /></div>

      
        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 0 && (
          <motion.div
           
            animate={{ height: "auto", opacity: 1 }}
          
            transition={{ duration: 0.2, ease: "easeInOut" }} className="faq-answer">
           <p className="react">Yes we will cover all react concepts in this course
        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>
      {/*  */}

      <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(1)} className="faq-question">
         <h3 className="react-heading"> What are prerequisites of this course</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown"/></div>
        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 1 && (
          <motion.div
               animate={{ height: "auto", opacity: 1 }}
          
          transition={{ duration: 0.2, ease: "easeInOut" }}
           className="faq-answer">
           <p className="react"> A laptop with internet connection, Hunger for Learning. Ready for a grind.

        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>
     {/*  third */}
     <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(2)} className="faq-question">
         <h3 className="react-heading"> What language will the course be taught in</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown"/></div>

        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 2 && (
          <motion.div
           
           animate={{ height: "auto", opacity: 1 }}
          
          transition={{ duration: 0.2, ease: "easeInOut" }} className="faq-answer">
           <p className="react"> mixup of Hindi + English
        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>

      {/* 4orth */}
      <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(3)} className="faq-question">
         <h3 className="react-heading">Is the course recorded or live</h3>
         <div className="icon"><RiArrowDropDownLine className="icon-dropdown" /></div>

        </div>
        <AnimatePresence initial={false}>
        {openQuestion === 3 && (
          <motion.div
           
           animate={{ height: "auto", opacity:1 }}
          
          transition={{ duration: 0.2, ease: "easeInOut" }} className="faq-answer">
           <p className="react">The course will be live and the recorded lecture we will provide you after class
        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>

      {/* fifith */}
      <div className="faq-item">
       <div className="main-question">
       <div onClick={() => toggleQuestion(4)} className="faq-question">
         <h3 className="react-heading"> Will you cover node-js </h3>
         <div className="icon-dropdown"><RiArrowDropDownLine className="icon-dropdown" /></div>
        </div>
      
        <AnimatePresence initial={false}>
        {openQuestion === 4 && (
          <motion.div
          
            animate={{ height: "auto" ,opacity:1}}
         
            transition={{ duration: 0.2, ease: "easeOut" }} className="faq-answer">
           <p className="react"> This is an intensive frontend React web series, we will not be covering Node.js.
        </p>
        </motion.div>

        )}
        </AnimatePresence>
       </div>
      </div>
      
        
</div>
</div>



<div className="footer-section">
        <div className="footer">
          <div className="footer-items">
            <li className='footer-items__list' onClick={toggleTermsModal}>Terms of Service</li>
            <li className='footer-items__list'  onClick={togglePrivacyModel}>Privacy Policy</li>
            <li className='footer-items__list'  onClick={ togglecontactModel}>Contact</li>
          </div>
        </div>
        <h3 className="footer-main__heading">@TheCodingshef</h3>
        <p className='copyright'>Copyright © 2024</p>
      </div>

      {/* Terms of Service Modal */}
      {isPrivacy && (
  <div className="modal-overlay">
    <div className="modal-content">
      <RiCloseLine className="close-close__icon" onClick={togglePrivacyModel} />
      <h2 style={{marginBottom:"1rem"}}>Privacy</h2>
      <p style={{marginBottom:"1rem"}}>Last updated: November 09, 2024
</p>
<p>This Privacy Policy describes Our policies and procedures on the collection,
use and disclosure of Your information when You use the Service and tells You
about Your privacy rights and how the law protects You.
</p>

<p>We use Your Personal data to provide and improve the Service. By using the
Service, You agree to the collection and use of information in accordance with
this Privacy Policy. This Privacy Policy has been created with the help of the
[Privacy Policy Generator](https://www.termsfeed.com/privacy-policy-
generator/).
</p>

<p>Interpretation and Definitions</p>
<p>The words of which the initial letter is capitalized have meanings defined
under the following conditions. The following definitions shall have the same
meaning regardless of whether they appear in singular or in plural.

</p>

<p>Definitions  </p>
<p>For the purposes of this Privacy Policy:
</p>
<div>
  <li> Account means a unique account created for You to access our Service or
    parts of our Service.
</li>
  <li>Affiliate means an entity that controls, is controlled by or is under
    common control with a party, where "control" means ownership of 50% or
    more of the shares, equity interest or other securities entitled to vote
    for election of directors or other managing authority.
</li>
  <li> Company (referred to as either "the Company", "We", "Us" or "Our" in this
    Agreement) refers to The CodingShef.</li>
  <li>Cookies are small files that are placed on Your computer, mobile device or
    any other device by a website, containing the details of Your browsing
    history on that website among its many uses.
</li>
  <li>Country refers to: Uttar Pradesh, India
</li>


<li>Service Provider means any natural or legal person who processes the data
    on behalf of the Company. It refers to third-party companies or
    individuals employed by the Company to facilitate the Service, to provide
    the Service on behalf of the Company, to perform services related to the
    Service or to assist the Company in analyzing how the Service is used.
</li>
<li>Third-party Social Media Service refers to any website or any social
    network website through which a User can log in or create an account to
    use the Service.
</li>
<li>Usage Data refers to data collected automatically, either generated by the
    use of the Service or from the Service infrastructure itself (for example,
    the duration of a page visit).
</li>
<li>Website refers to The CodingShef, accessible from
  <a href="https://thecodingshef.in/">https://thecodingshef.in/</a>
</li>
<li>You means the individual accessing or using the Service, or the company,
    or other legal entity on behalf of which such individual is accessing or
    using the Service, as applicable.
</li>
</div>
<p>Collecting and Using Your Personal Data </p>
<p>Types of Data Collected </p>
<p>Personal Data  
</p>
<p>While using Our Service, We may ask You to provide Us with certain personally
identifiable information that can be used to contact or identify You.
Personally identifiable information may include, but is not limited to:
</p>

<li> Email address
</li>
<li>First name and last name

</li>
<li>Phone number
</li>
<li>Address, State, Province, ZIP/Postal code, City

</li>
<li> Usage Data
</li>

<p>Usage Data  
</p>
<p>Usage Data is collected automatically when using the Service.</p>
<p>Usage Data may include information such as Your Device's Internet Protocol
address (e.g. IP address), browser type, browser version, the pages of our
Service that You visit, the time and date of Your visit, the time spent on
those pages, unique device identifiers and other diagnostic data.

When You access the Service by or through a mobile device, We may collect
certain information automatically, including, but not limited to, the type of
mobile device You use, Your mobile device unique ID, the IP address of Your
mobile device, Your mobile operating system, the type of mobile Internet
browser You use, unique device identifiers and other diagnostic data.

We may also collect information that Your browser sends whenever You visit our
Service or when You access the Service by or through a mobile device.
</p>

<p>Information from Third-Party Social Media Services </p>
<p>
The Company allows You to create an account and log in to use the Service
through the following Third-party Social Media Services:
</p>
<li>Google
</li>
<li> Facebook
  
</li>
<li>Instagram
 </li>
<li>
LinkedIn</li>
<li>  Twitter</li>

<p>If You decide to register through or otherwise grant us access to a Third-
Party Social Media Service, We may collect Personal data that is already
associated with Your Third-Party Social Media Service's account, such as Your
name, Your email address, Your activities or Your contact list associated with
that account.
</p>
<p>You may also have the option of sharing additional information with the
Company through Your Third-Party Social Media Service's account. If You choose
to provide such information and Personal Data, during registration or
otherwise, You are giving the Company permission to use, share, and store it
in a manner consistent with this Privacy Policy.
</p>
<p>Tracking Technologies and Cookies  </p>
<p>
We use Cookies and similar tracking technologies to track the activity on Our
Service and store certain information. Tracking technologies used are beacons,
tags, and scripts to collect and track information and to improve and analyze
Our Service. The technologies We use may include:
</p>
<li>Cookies or Browser Cookies. A cookie is a small file placed on Your
    Device. You can instruct Your browser to refuse all Cookies or to indicate
    when a Cookie is being sent. However, if You do not accept Cookies, You
    may not be able to use some parts of our Service. Unless you have adjusted
    Your browser setting so that it will refuse Cookies, our Service may use
    Cookies.</li>
<li>
Web Beacons. Certain sections of our Service and our emails may contain
    small electronic files known as web beacons (also referred to as clear
    gifs, pixel tags, and single-pixel gifs) that permit the Company, for
    example, to count users who have visited those pages or opened an email
    and for other related website statistics (for example, recording the
    popularity of a certain section and verifying system and server
    integrity).


</li>

<p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on
Your personal computer or mobile device when You go offline, while Session
Cookies are deleted as soon as You close Your web browser. You can learn more
about cookies on [TermsFeed
website](https://www.termsfeed.com/blog/cookies/#What_Are_Cookies) article</p>
<p>We use both Session and Persistent Cookies for the purposes set out below:
</p>
<li>Necessary / Essential Cookies

Type: Session Cookies

Administered by: Us

Purpose: These Cookies are essential to provide You with services
available through the Website and to enable You to use some of its
features. They help to authenticate users and prevent fraudulent use of
user accounts. Without these Cookies, the services that You have asked for
cannot be provided, and We only use these Cookies to provide You with
those services.
</li>
<li> Cookies Policy / Notice Acceptance Cookies

Type: Persistent Cookies

Administered by: Us

Purpose: These Cookies identify if users have accepted the use of cookies
on the Website.
</li>
<li>Functionality Cookies

Type: Persistent Cookies

Administered by: Us

Purpose: These Cookies allow us to remember choices You make when You use
the Website, such as remembering your login details or language
preference. The purpose of these Cookies is to provide You with a more
personal experience and to avoid You having to re-enter your preferences
every time You use the Website.

</li>
<p>For more information about the cookies we use and your choices regarding
cookies, please visit our Cookies Policy or the Cookies section of our Privacy
Policy.
</p>
<p>Use of Your Personal Data  
</p>

<p>The Company may use Personal Data for the following purposes:
</p>
<li>To provide and maintain our Service , including to monitor the usage of
    our Service.
</li>
<li>To manage Your Account: to manage Your registration as a user of the
    Service. The Personal Data You provide can give You access to different
    functionalities of the Service that are available to You as a registered
    user.

</li>
<li>For the performance of a contract: the development, compliance and
    undertaking of the purchase contract for the products, items or services
    You have purchased or of any other contract with Us through the Service.

</li>
<li>To contact You: To contact You by email, telephone calls, SMS, or other
    equivalent forms of electronic communication, such as a mobile
    application's push notifications regarding updates or informative
    communications related to the functionalities, products or contracted
    services, including the security updates, when necessary or reasonable for
    their implementation.
</li>
<li>To provide You with news, special offers and general information about
    other goods, services and events which we offer that are similar to those
    that you have already purchased or enquired about unless You have opted
    not to receive such information.
</li>

<li>To manage Your requests: To attend and manage Your requests to Us.

</li>
<li>For business transfers: We may use Your information to evaluate or conduct
    a merger, divestiture, restructuring, reorganization, dissolution, or
    other sale or transfer of some or all of Our assets, whether as a going
    concern or as part of bankruptcy, liquidation, or similar proceeding, in
    which Personal Data held by Us about our Service users is among the assets
    transferred.
</li>
<li>For other purposes : We may use Your information for other purposes, such
    as data analysis, identifying usage trends, determining the effectiveness
    of our promotional campaigns and to evaluate and improve our Service,
    products, services, marketing and your experience.

</li>
<p>We may share Your personal information in the following situations:

</p>

<li>With Service Providers: We may share Your personal information with
    Service Providers to monitor and analyze the use of our Service, to
    contact You.</li>
    <li>For business transfers: We may share or transfer Your personal information
    in connection with, or during negotiations of, any merger, sale of Company
    assets, financing, or acquisition of all or a portion of Our business to
    another company.</li>
    <li>With Affiliates: We may share Your information with Our affiliates, in
    which case we will require those affiliates to honor this Privacy Policy.
    Affiliates include Our parent company and any other subsidiaries, joint
    venture partners or other companies that We control or that are under
    common control with Us.</li>
    <li>With business partners: We may share Your information with Our business
    partners to offer You certain products, services or promotions.
</li>
<li>With other users: when You share personal information or otherwise
    interact in the public areas with other users, such information may be
    viewed by all users and may be publicly distributed outside. If You
    interact with other users or register through a Third-Party Social Media
    Service, Your contacts on the Third-Party Social Media Service may see
    Your name, profile, pictures and description of Your activity. Similarly,
    other users will be able to view descriptions of Your activity,
    communicate with You and view Your profile.
</li>
<li>With Your consent : We may disclose Your personal information for any
    other purpose with Your consent.

</li>
<p>Retention of Your Personal Data  </p>
<li>The Company will retain Your Personal Data only for as long as is necessary
for the purposes set out in this Privacy Policy. We will retain and use Your
Personal Data to the extent necessary to comply with our legal obligations
(for example, if we are required to retain your data to comply with applicable
laws), resolve disputes, and enforce our legal agreements and policies.
</li>
<li>The Company will also retain Usage Data for internal analysis purposes. Usage
Data is generally retained for a shorter period of time, except when this data
is used to strengthen the security or to improve the functionality of Our
Service, or We are legally obligated to retain this data for longer time
periods.

</li>
<p>Transfer of Your Personal Data  </p>
<li>Your information, including Personal Data, is processed at the Company's
operating offices and in any other places where the parties involved in the
processing are located. It means that this information may be transferred to —
and maintained on — computers located outside of Your state, province, country
or other governmental jurisdiction where the data protection laws may differ
than those from Your jurisdiction.
</li>
<li>
Your consent to this Privacy Policy followed by Your submission of such
information represents Your agreement to that transfer.

</li>

<li>The Company will take all steps reasonably necessary to ensure that Your data
is treated securely and in accordance with this Privacy Policy and no transfer
of Your Personal Data will take place to an organization or a country unless
there are adequate controls in place including the security of Your data and
other personal information.
</li>
<p>Delete Your Personal Data  </p>

<p>You have the right to delete or request that We assist in deleting the
Personal Data that We have collected about You.

</p>
<p>Our Service may give You the ability to delete certain information about You
from within the Service.

</p>
<p>You may update, amend, or delete Your information at any time by signing in to
Your Account, if you have one, and visiting the account settings section that
allows you to manage Your personal information. You may also contact Us to
request access to, correct, or delete any personal information that You have
provided to Us.

</p>
<p>Please note, however, that We may need to retain certain information when we
have a legal obligation or lawful basis to do so.

</p>

<p>Disclosure of Your Personal Data</p>
<p>Business Transactions  
</p>
<p>If the Company is involved in a merger, acquisition or asset sale, Your
Personal Data may be transferred. We will provide notice before Your Personal
Data is transferred and becomes subject to a different Privacy Policy.
</p>
<p>Law enforcement  </p>
<p>Under certain circumstances, the Company may be required to disclose Your
Personal Data if required to do so by law or in response to valid requests by
public authorities (e.g. a court or a government agency).
</p>

<p>Other legal requirements  
</p>

<p>The Company may disclose Your Personal Data in the good faith belief that such
action is necessary to:
</p>

<li>Comply with a legal obligation
</li>
<li>Protect and defend the rights or property of the Company
</li>
<li>Prevent or investigate possible wrongdoing in connection with the Service</li>
<li>Protect the personal safety of Users of the Service or the public
</li>
<li>Protect against legal liability
</li>
<p>Security of Your Personal Data  </p>

<p>The security of Your Personal Data is important to Us, but remember that no
method of transmission over the Internet, or method of electronic storage is
100% secure. While We strive to use commercially acceptable means to protect
Your Personal Data, We cannot guarantee its absolute security.

</p>
<p>Children's Privacy  </p>
<p>Our Service does not address anyone under the age of 13. We do not knowingly
collect personally identifiable information from anyone under the age of 13.
If You are a parent or guardian and You are aware that Your child has provided
Us with Personal Data, please contact Us. If We become aware that We have
collected Personal Data from anyone under the age of 13 without verification
of parental consent, We take steps to remove that information from Our
servers.

If We need to rely on consent as a legal basis for processing Your information
and Your country requires consent from a parent, We may require Your parent's
consent before We collect and use that information.

</p>
<p>Links to Other Websites </p>

<p>Our Service may contain links to other websites that are not operated by Us.
If You click on a third party link, You will be directed to that third party's
site. We strongly advise You to review the Privacy Policy of every site You
visit.
</p>
<p>We have no control over and assume no responsibility for the content, privacy
policies or practices of any third party sites or services.
</p>

<p>Changes to this Privacy Policy  
</p>

<p>
We may update Our Privacy Policy from time to time. We will notify You of any
changes by posting the new Privacy Policy on this page.

</p>
<p>We will let You know via email and/or a prominent notice on Our Service, prior
to the change becoming effective and update the "Last updated" date at the top
of this Privacy Policy.
</p>

<p>You are advised to review this Privacy Policy periodically for any changes.
Changes to this Privacy Policy are effective when they are posted on this
page.

</p>
<p>Contact Us  
</p>

<p>If you have any questions about this Privacy Policy, You can contact us:

</p>

<li>By email: thecodingshef@gmail.com
</li>
<li className='last terms'>By visiting this page on our website: https://thecodingshef.in/contactus
</li>
    </div>
  </div>
)}


{isTermsOpen && (
  <div className="modal-overlay">
    <div className="modal-content">
      <RiCloseLine className="close-close__icon" onClick={toggleTermsModal} />
      <h2 style={{marginBottom:"1rem"}}>Terms of Service</h2>
     <p>These Terms of Use set out the terms and conditions for use of this TheCodingShef (
“Website”) and any content, Public Forums, or services offered on or through the Website
and/or through any mobile application(s) ( “Application”) (collectively referred to as the
“Platform”).</p>

<p>These Terms of Use apply to end users of the Website (referred to as “Learners”, “You”,
“Your”). These Terms of Use, including the Privacy Policy and any other terms and conditions
published on the Platform or communicated to you from time to time (collectively referred to as
the “Agreement”), define the relationship and responsibilities between You and Creator (as
defined herein) in using the Platform. Your access to the Platform is subject to Your acceptance
of this Agreement. Hence, please take Your time to read this Agreement.</p>

<p>When we speak of “Creator”, ‘we’, ‘us’, and ‘our’, we collectively mean TheCodingShef being
the creator of this Platform and the content/materials/services contained therein.</p>

<p>By accessing this Platform, You are agreeing to be bound by the terms of this Agreement, all
applicable laws and regulations. From time-to-time, versions of the above-mentioned policies
and terms are made available on the Platform for Your reference and to understand how we
handle Your personal information. By using or visiting the Platform and services provided to You
on, from, or through the Platform, You are expressly agreeing to the terms of the Agreement and
any other terms that are updated from time to time.</p>

<p>If You disagree with any part of this Agreement or do not wish to be bound by the same, then
please do not use the Platform in any manner.</p>

<li>Access and Registration: If You’re an individual You must be at least 18 (eighteen)
years of age, or, if You are between the ages of 13 and 18, You must have Your parent or
legal guardian's permission to use the Platform. By using the Platform, You are, through
Your actions, representing and warranting to us that You have obtained the appropriate
consents/permissions to use the Platform. If You are under the age of 13 years or 16
years (depending on your country of reisdence), You may neither use our Platform in any
manner nor may You register for any content or services offered therein.
To access any Content (as defined below) offered on the Platform, we require You to
register for the same by providing Your name and email address. Please read our
Privacy Policy to understand how we handle Your information. Further, You may also be
required to make payment of a fee to access the Content, if applicable. For more
information, please read our ‘Payments & Refunds’ section below. You represent that the
information indicated by You during Your enrolment or registration for any Content on the
Platform, is true and complete, that You meet the eligibility requirements for use of the
Platform and the Content offered therein, and You agree to update us upon any changes
to the information by writing to us at the contact information provided in the ‘Contact Us’
section below.</li>
<p>For the purpose of this Agreement, “Content” shall mean and include any course or
session (whether pre-recorded or live) published by the Creator on the Platform,
including, but not limited to any reference materials and text files (if any) offered to You
as part of the Content.</p>
<p>When You register or enrol for any Content on the Platform, You may also have access
to discussion forums that enables You to exchange Your thoughts, knowledge in relation
to the Content or its subject-matter, with us and other registrants to the Content (“Public
Forum”). Participating in the Public Forum is completely Your choice and by registering
or enrolling to a Content on the Platform, You are not obligated to participate in such
Public Forum.</p>
<p>We maintain and reserve the right to refuse access to the Platform or remove content
posted by You in the Public Forums, at any time without notice to You, if, in our opinion,
You have violated any provision of this Agreement.</p>
<p>Further, to access the Platform and/or view the content on the Platform, You will need to
use a “Supported/Compatible Device” which means a personal computer, mobile
phone, portable media player, or other electronic device that meets the system and
compatibility requirements and on which You are authorized to operate the Platform. The
Supported/Compatible Devices to access the Platform may change from time to time
and, in some cases, whether a device is (or remains) a Supported/Compatible Device
may depend on software or systems provided or maintained by the device manufacturer
or other third parties. Accordingly, devices that are Supported/Compatible Devices at one
time may cease to be Supported/Compatible Devices in the future. Thus, kindly make
sure that the device that You use is compatible with any of the systems/software used on
the Platform to access the content/material offered therein</p>
<p>License to Use: You are granted a limited, non-exclusive license to access and view
the Content on the Platform for Your own personal, non-commercial use only. Further, if
so allowed on the Platform, You may temporarily download one copy of any
downloadable Content [including Creator Content (defined below)] on the Platform for
personal and non-commercial transitory viewing only.</p>
<p>This license does not grant You the right to assign or sublicense the license granted
under this Agreement to anyone else. Further, You may not</p>

<li>. modify, edit or copy the Content, Creator Content or any material made available
on the Platform;</li>
<li>create derivative works or exploit any material made available on the Platform
(including the Content and Creator Content) or any portion thereof (including, but
not limited to any copyrighted material, trademarks, or other proprietary
information contained therein) in manner that is not permitted under this license
granted to You;</li>
<li>publicly display (commercially or non-commercially) the Content, Creator Content
or any material made available on the Platform or otherwise use the same for any
commercial purpose;</li>
<li>attempt to decompile or reverse engineer any software contained in the Platform;</li>
<li>remove any copyright or other proprietary notations from the Content, Creator
Content or any material made available on the Platform; or</li>
<li>transfer the any material made available on the Platform to another person or
'mirror' the same on any other server.</li>
<li>For the purpose of this Agreement, “Creator Content” shall mean and include any
audio files, video files, audio-visual files, images, text materials (including .doc, .docx,
and .pdfs) (other than the Content) uploaded or otherwise published on the Platform by
the Creator to be accessed by You, including, but not limited to any such content/material
posted by the Creator in any Public Forum (defined below).This license shall automatically terminate if You violate any of these restrictions and may
be terminated by us at any time. Upon termination of this license granted to You or Your
viewing of any material on the Platform (including Content and Creator Content), You
must destroy any downloaded materials in Your possession (whether in electronic or
printed format).</li>

<li>Communications: The Platform includes provision and facilitation of Public Forums
designed to enable You to communicate with us and other registrants to the Content You
have registered for. As stated above, use of these Public Forums are completely your
choice and by registering for a Content, you are not obligated to participate in the Public
Forum. However, if You choose to participate, You agree to adhere to the terms specified
in the ‘Code of Conduct’ section hereinbelow and such other terms as may be
published on our Platform. If it comes to our notice that Your conduct is in violation of the
terms of this Agreement, then we may terminate or suspend Your access to any Public
Forums at any time, without notice, for any reason. You represent and warrant that You
own and control all rights in and to any content (including without limitation chats,
postings, or materials) uploaded or posted by You on the Public Forums or anywhere on
the Platform (“Learner Content”), or that You are licensed to use and reproduce such
Learner Content. We are not responsible for the information that You choose to share on
the Public Forums, or for the actions of other users therein. You further understand and
agree that You, shall be solely responsible for the Learner Content including its legality,
reliability, accuracy, and appropriateness, and the consequences of its publication.
Further, if you do post content or submit any Learner Content on the Platform, and unless otherwise indicated by You in writing (emails included) to us, You hereby grant us
a non-exclusive, royalty-free, irrevocable, perpetual and fully sublicensable rights to use,
reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and
display such Learner Content throughout the world in any media.</li>
<p>Code of Conduct: You agree to the following:</p>
<li>Legitimate usage of the Platform: You agree to use the Platform only for lawful
purposes and You are not allowed to use our Platform to engage in any kind of
activity that violates any applicable central, state, local, federal or international
law or regulation (including, without limitation, any laws regarding the export of
data or software to and from the USA or other countries). Also, You agree that
You will not use the Platform in any manner that would disrupt, damage or impair
the Platform or access to it, in any manner, including promoting or encouraging
illegal activity such as hacking, cracking or distribution of counterfeit software,
compromised accounts, or cheats or hacks for the Platform and conduct of any
form of fraudulent activity</li>
<li>No harmful or dangerous content: Any content which incites or promotes
violence, that may cause physical or emotional harm or that may endanger the
safety of any individual or is otherwise objectionable is expressly prohibited on
the Platform. The Platform is to be used only for the permitted uses as detailed
under this Agreement</li>
<li>No hateful or defamatory content: We realise that there may be instances
when there are exchange of ideas and opinions which is essential in the learning
process, while we agree that individuals have the right to voice their opinion, we
do not encourage or tolerate any form of hate speech or statements that are
libelous, slanderous, threatening, violent, predatory, defamatory, or any statement
that incites hatred against specific individuals or groups with respect to but not
limited to race or ethnic origin, country caste, religion, disability, gender, age,
sexual orientation/gender identity etc.</li>
<li>Violent and graphic content: Any content, the sole objective of which is to
sensationalise, shock or disturb individuals is not allowed. We do not allow any
content that promotes terrorist acts or incites violence, to be uploaded on the
Platform in any manner.
</li>
<li>Harassment and bullying: We do not tolerate any form of harassment or
bullying on the Platform and strive to keep the Platform a safe space to foster learning. Harassment in this case would include, without limitation, to abusive
videos, comments, messages, revealing someone’s personal information,
including sensitive personally identifiable information of individuals, content or
comments uploaded in order to humiliate someone, sexual harassment or sexual
bullying in any form.
</li>
<li>Spam: Posting untargeted, unwanted and repetitive content, comments or
messages with an intention to spam a Public Forum or otherwise the Platform
and to drive traffic from the Platform to other third-party sites is in direct violation
of this Agreement. Posting links to external websites with malware and other
prohibited sites is not allowed.
</li>

<li>Scams: Any content uploaded or posted in order to trick others for their own
financial gain is not allowed and we do not tolerate any practices of extortion or
blackmail, either.</li>

<li>Privacy violation: Kindly refer to our Privacy Policy to know how to protect Your
privacy and respect the privacy of other Users.</li>
<li>Impersonation: Impersonating another person, including but not limited to,
another learner, is not permitted while using our Platform. In this case
impersonation would mean the intention to cause confusion regarding who the
real person is by pretending to be them (such as using names, image,
documents, certificates etc. not belonging to You or not used to identify You, or
pretending to be a company, institute, group etc., by using their logo, brand name
or any distinguishing mark).</li>

<li>Unauthorized Access or Disabling of Platform: You agree not to (i) use the
Platform in any manner that could disable, overburden, damage, or impair the
Platform or interfere with any other user’s use of the Platform; (ii) not to use any
manual process to monitor or copy any of the material on the Platform or for any
unauthorized purpose; (iii) use any device, software, or routine that interferes with
the proper working of the Platform; (iv) attack the Platform via a denial-of-service
attack; (v) attempt to gain unauthorized access to, interfere with, or disrupt any
parts of the Platform, the server on which the Platform is stored, or any server,
computer, or database connected to or associated with the Platform; and (vi)
introduce any viruses, trojan horses, worms, keystroke loggers, malware, or other
material which is malicious or technologically harmful to the Platform.</li>

<li>If any violation of the above rules of conduct comes to our notice, then, we
reserve the right to refuse Your access to the Platform, terminate accounts or
remove such violating content at any time without notice to You.</li>

<li>We own all information and materials, including Content and Creator Content (in
whatever form or media) provided or communicated to You by or on behalf of us
including but not limited to, the Platform, trademarks, trade dress, logos, wordmarks,
illustrations, letters, images, ideas, concepts, the layout, design, flow, look and feel of the
Platform, logos, marks, graphics, audio files, video files, any software which is owned by
or licensed to us, instructions embedded in any form of digital documents and other data,
information, or material made available to You by us (“Creator’s Intellectual
Property”). Creator’s Intellectual Property, including the copyrights and trademarks
contained therein, may not be modified by You in any way. You acknowledge and agree
that You do not acquire any ownership rights to Creator’s Intellectual Property by use of
the Platform or any part thereof. You acknowledge and agree that the Creator’s
Intellectual Property is protected by the applicable intellectual property laws, including
international copyright, trademark, patent, trade secret and other intellectual property or
proprietary rights laws and any unauthorized use, reproduction, modification, distribution,
transmission, republication, display or performance of the Creator’s Intellectual Property
or any component thereof is strictly prohibited.</li>

<li>Feedback: If You submit suggestions, ideas, comments, or questions containing product
feedback about any Content, the Platform or any part thereof, either through the Public
Forum or otherwise (“Feedback”), then You grants to us a worldwide, non-exclusive,
royalty-free, perpetual, and irrevocable right to use (and full right to sublicense),
reproduce, modify, adapt, publish, translate, create derivative works from, distribute,
transmit, and display such Feedback in any form. You shall have no intellectual property
right in any Content, Platform or any part thereof, as a result of our incorporation of
Feedback into any Content or the Platform.</li>

<li>Payments and Refunds: To register/enrol for any Content, You may need to pay a fee
as may be applicable (“Content Fee”). Please refer to our Platform to know the pricing.
Payment of such Content Fee shall be processed through third-party payment
processors. Your payments may be subject to applicable taxes, so we suggest that You
read terms and policies of of such third party payment processors to understand the
same better. Once You purchase access to a Content on the Platform, the same cannot
be cancelled and there shall be no refund of the Content Fee, unless otherwise stated in
our Refund Policy.
As stated above, we use third-party service providers to enable You to make payment
for the purchases made on the Platform. Accordingly, it is hereby clarified that we do not
capture and/or store any of your sensitive personal information. While making payments
through such third-party payment gateways/service providers kindly ensure to read
through their terms and conditions.</li>

<li>. Disclaimer: THE PLATFORM IS PROVIDED TO YOU "AS IS" AND "AS AVAILABLE"
AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO
THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE CREATOR,
ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR
RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS
ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
WITH RESPECT TO THE PLATFORM, INCLUDING ALL IMPLIED WARRANTIES OF
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT
LIMITATION TO THE FOREGOING, THE CREATOR PROVIDES NO WARRANTY OR
UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE
PLATFORM OR THE SERVICES OR PRODUCTS OFFERED THEREIN WILL MEET
YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR
WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES,
OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY
STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR
WILL BE CORRECTED.WITHOUT LIMITING THE FOREGOING, THE CREATOR MAKES NO
REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED: (I) AS
TO THE OPERATION OR AVAILABILITY OF THE PLATFORM, OR THE
INFORMATION, CONTENT, AND MATERIALS OR PRODUCTS INCLUDED THEREON;
(II) THAT THE PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE; (III) AS TO
THE ACCURACY, RELIABILITY, OR CURRENCY OF ANY INFORMATION OR
CONTENT PROVIDED THROUGH THE PLATFORM; OR (IV) THAT THE PLATFORM,
ITS SERVERS, THE CONTENT, OR E-MAILS SENT FROM OR ON BEHALF OF THE
CREATOR ARE FREE OF VIRUSES, SCRIPTS, TROJAN HORSES, WORMS,
MALWARE, TIMEBOMBS OR OTHER HARMFUL COMPONENTS.
SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN TYPES OF
WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A
CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS
MAY NOT APPLY TO YOU. BUT IN SUCH A CASE THE EXCLUSIONS AND
LIMITATIONS SET FORTH IN THIS SECTION SHALL BE APPLIED TO THE,GREATEST EXTENT ENFORCEABLE UNDER APPLICABLE LAW</li>


<p>Limitation of Liability: In no event shall the Creator be liable for any damages
(including, without limitation, damages for loss of data or profit, or due to business
interruption) arising out of the use or inability to use the Content or any other materials
on the Platform, even if the Creator or any authorized personnel of the Creator has been
notified orally or in writing of the possibility of such damage. Some jurisdictions do not
allow limitations on implied warranties, or limitations of liability for consequential or
incidental damages, so, some or all of these limitations may not apply to You.</p>

<p>Indemnity and Release: You shall indemnify and hold harmless the Creator and where
applicable, its officers, directors, agents and employees, from any claim or demand, or
actions including reasonable attorney's fees, made by any third party or penalty imposed
due to or arising out of Your breach of this Agreement or any document incorporated by
reference, or Your violation of any law, rules, regulations or the rights of a third party</p>

<p>Links to Third Party Website: Creator has not reviewed all of the sites linked to its
Platform and is not responsible for the contents of any such linked site. The inclusion of
any link does not imply endorsement by the Creator of such site. Use of any such linked
website is at the Your own risk.</p>

<p>Governing Law and Jurisdiction: Any claim relating to the Platform shall be governed
by the laws of the Creator’s home jurisdiction (as provided on the Platform) without
regard to its conflict of law provisions. You agree, as we do, to submit to the exclusive
jurisdiction of the courts at Creator’s home jurisdiction.</p>

<p>. Miscellaneous:</p>

<li>Alteration of Platform or Amendments to the Conditions: We reserve the
right to make changes to our Platform, policies, and this Agreement at any time.
We will post the new terms with a revision date indicated at the top or if deemed
practicable. You should check our Platform frequently to see recent changes. You
will be subject to the Agreement and the policies in force at the time that You use
the Platform or any part thereof, unless any change to those policies or these
conditions is required to be made by law or government authority (in which case it
will apply to orders previously placed by You). If any of these conditions is
deemed invalid, void, or for any reason unenforceable, that condition will be
deemed severable and will not affect the validity and enforceability of any
remaining condition.</li>
<li>Waiver: If You breach these conditions and we take no action, we will still be
entitled to use our rights and remedies in any other situation where You breach
these conditions.</li>
<li>Assignment: You may not assign or transfer this Agreement, by operation or law
or otherwise. Any attempt by You to assign or transfer this Agreement will be null
and void.</li>
<li>Severability: If any provision of this Agreement will be adjudged by any court of
competent jurisdiction to be unenforceable or invalid, that provision will be limited
to the minimum extent necessary so that this Agreement will otherwise remain in
effect.</li>
<li>Events beyond our reasonable control: We will not be held responsible for any
delay or failure to comply with our obligations under these conditions if the delay
or failure arises from any cause which is beyond our reasonable control. This
condition does not affect Your statutory rights.</li>

<p>Contact Us: If You’ve have concerns or queries regarding this Agreement, You may
write to us by email at thecodingshef@gmail.com.</p>
    </div>
  </div>
)}


{isHelp && (
  <div className="contact-modal">
    <div className="contact-content">
      <RiCloseLine className="close-icon" onClick={ togglecontactModel} />
      <h2>Contact</h2>
      <div className="center-model">
        <p>Have any questions or need more information? We're here to help!</p>
        <p>Reach out to us, and our team will get back to you as soon as possible. Whether you’re looking for support or just want to say hello, we’d love to hear from you!
</p>

<p>  Email: thecodingshef@gmail.com</p>
<p>Operational Address : Gurugram ,Haryana</p>
<a href="https://api.whatsapp.com/send?phone=918279480353"><button className="btn" style={{marginTop:"3rem",backgroundColor:"black"}}>Start chat with us</button></a>
      </div>
    </div>
  </div>
)}





    </div>
  );
}
