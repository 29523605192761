import React from 'react'
import './Workshop.scss';
import Banner from '../asset/icons/Banner.jpeg'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
const Workshop = () => {
  return (
    <div className='workshop-container'>
      <div className="workshop">
        <div className="workshop-heading"><h1 className="heading">Live Workshops</h1></div>
        <div className="live-workshop">
        <div className="live-section">
            <img src={Banner} alt="" className="workshop-img" />
            <h4 className='live-last__heading'>Kickstart your Front-End Journey with React </h4>
            <h4 className='live-last__heading'>Sunday 17Nov-2024</h4>
            <h4 className='live-last__heading'>Time : 3:00pm</h4>
            <div className="workshop-lower__section">
                <h3 className='live-heading'>Live {' '}<motion.span
                        animate={{ scale: [1, 1.2, 1] }} // Pulsing effect
                        transition={{
                            duration: 1, // Duration of one cycle
                            repeat: Infinity, // Infinite looping
                            ease: "easeInOut" // Smooth transitions
                        }}
                        style={{ display: 'inline-block' }} // Keeps the emoji inline
                    >
                        🔴
                    </motion.span></h3>
               <Link to='/workshop'> <button className="live-btn">Book your seat</button></Link>
            </div>
          
        </div>

        {/* <div className="live-section">
            <img src={Banner} alt="" className="workshop-img" />
            <h4 className='live-last__heading'>Live Workshop on Sunday 17Nov-2024</h4>
            <div className="workshop-lower__section">
                <h3 className='live-heading'>Live</h3>
                <Link to='/workshop'> <button className="live-btn">Register</button></Link>
            </div>
          
        </div> */}
        
      </div>


      </div>

      
    </div>
  )
}

export default Workshop
