import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./EmployeeSlider.scss";

import img1 from '../asset/icons/img1.png'
import img2 from '../asset/icons/img2.png'
import img3 from '../asset/icons/img3.png'
import img4 from '../asset/icons/img4.png'
import img5 from '../asset/icons/img5.png'
import img6 from '../asset/icons/img6.png'
import img7 from '../asset/icons/img7.png'
import img8 from '../asset/icons/img8.png'
import img9 from '../asset/icons/img9.png'
import img10 from '../asset/icons/img10.png'
import img12 from '../asset/icons/img12.png'
import img13 from '../asset/icons/img13.png'
const employees = [
  { id: 1, image:img1},
  { id: 2, image:img2 },
  { id: 3,image:img3 },
  { id: 4, image:img4 },
  { id: 5, image:img5},
  { id: 6, image:img6},
  { id: 1, image:img7},
  { id: 2, image:img8 },
  { id: 3,image:img9 },
  { id: 4, image:img10 },
  { id: 5, image:img12},

  { id: 5, image:img13},


  // Add more employees as needed
];

const EmployeeSlider = () => {
  
  const [isPaused, setIsPaused] = useState(false);

  // Handler to pause the slider on click and resume after 3 seconds
  const handleCardClick = () => {
    setIsPaused(true); // Pause the slider
    setTimeout(() => {
      setIsPaused(false); // Resume the slider after 3 seconds
    }, 3000);
  };
  return (
    <div className="slider-container">
    <motion.div
      className="slider-row"
      initial={{ x: 0 }}
        animate={{ x: isPaused ? undefined : -employees.length * 300 }}
        transition={{
          repeat: Infinity,
          duration: employees.length * 10, // Further slowed down by increasing duration
          ease: 'linear',
          repeatDelay: isPaused ? 3 : 0, // Pause if `isPaused` is true
        }}
        onAnimationComplete={(definition) => {
          // Reset to start after each full loop
          if (!isPaused) definition.x = 0;
        }}
    >
      {[...employees, ...employees].map((employee, index) => (
        <motion.div
          key={index}
          className="employee-card"
          onClick={handleCardClick}
        >
          <img className="slide-image" src={employee.image} alt={employee.name} />
          {/* <h2>{employee.name}</h2>
          <h4>{employee.about}</h4>
          <p>{employee.type}</p> */}
        </motion.div>
      ))}
    </motion.div>
  </div>
  );
};

export default EmployeeSlider;
