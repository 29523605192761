import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginForm.scss';
import axios from 'axios';
import { Link } from 'react-router-dom';

function LoginForm() {

   
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

       
        try {
            const response = await axios.post('https://thecodingshef-be.vercel.app/auth/login', {
                email,
                password,
            });

            if (response.data) {
              
                navigate('/home'); // Redirect to homepage on successful login
            }
        } catch (err) {
            setError('Login failed. Please check your credentials and try again.');
        }
    };

    return (
        <div className='loginform-section'>
            <div className="login-form">
                <div className="login-container">
                    <div className="login-fields">
                        <h3 className="form-heading">Login</h3>
                        <div className="form-fields">
                            <form onSubmit={handleSubmit}>
                                <div className="email">
                                    <p>Email<sup style={{color:"red"}}>*</sup></p>
                                    <input
                                        type="email"
                                        value={email}
                                        placeholder="Enter your email"
                                        className="form"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="password">
                                    <p>Password<sup style={{color:"red"}}>*</sup></p>
                                    <input
                                        type="password"
                                        value={password}
                                        placeholder="Enter your password"
                                        className="form"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="account">
  <p className='account-txt'>Create an account</p> <Link to="/signup" style={{textDecoration:"none",color:"black",}}><p className='account-txt-login'>Signup</p></Link>
</div>
                                <div className="button">
                                    <button type="submit" className="login-btn">Login</button>
                                </div>
                            </form>
                            {error && <p className="last-error-message">{error}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;
