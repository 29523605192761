import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Signup.scss';
import axios from 'axios';
import './Workshopform.scss';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Workshopform() {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [year, setYear] = useState('');

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [yearError, setYearError] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Reset error messages
        setNameError('');
        setEmailError('');
        setPhoneError('');
        setYearError('');

        let isValid = true;

        // Basic validation
        if (!name) {
            setNameError('This field is required');
            isValid = false;
        }
        if (!email) {
            setEmailError('This field is required');
            isValid = false;
        }
        if (!phone) {
            setPhoneError('This field is required');
            isValid = false;
        }
        if (!year) {
            setYearError('This field is required');
            isValid = false;
        }
        if (!isValid) return;

        // Prepare form data
        const formData = { Name: name, Email: email, Phone: phone, Year: year };

        try {
            const response = await axios.post(
                'https://api.sheetbest.com/sheets/917e7f38-fc1a-4ba6-b59a-4de4b8e82338',
                formData
            );

            console.log('API Response:', response.data);

            // Show success notification
            toast.success('Registration successful!', {
              position: "top-right", // Corrected position syntax
          });

            // Clear form fields
            setName('');
            setEmail('');
            setPhone('');
            setYear('');

            // Navigate after 2 seconds
            setTimeout(() => navigate('/home'), 2000);
        } catch (error) {
            console.error('Error Submitting Form:', error.response ? error.response.data : error.message);
            toast.error('Registration failed. Please try again.', {
                position: "top-right",
            });
        }
    };

    return (
        <div className="workshopform-section">
            <ToastContainer />
            <div className="workshopform-form">
                <div className="form-container">
                    <div className="login-fields">
                        <h3 className="form-heading">Register</h3>
                        <div className="form-fields">
                            <form onSubmit={handleSubmit}>
                                <div className="name">
                                    <p>Name<sup style={{ color: "red" }}>*</sup></p>
                                    <input type="text" value={name} placeholder="Enter your name" className="form" onChange={(e) => setName(e.target.value)} />
                                    {nameError && <p className="error-message">{nameError}</p>}
                                </div>
                                <div className="signup-email">
                                    <p>Email<sup style={{ color: "red" }}>*</sup></p>
                                    <input type="email" value={email} placeholder="Enter your email" className="form" onChange={(e) => setEmail(e.target.value)} />
                                    {emailError && <p className="error-message">{emailError}</p>}
                                </div>
                                <div className="year">
                                    <p>Graduation Year<sup style={{ color: "red" }}>*</sup></p>
                                    <input type="text" value={year} placeholder="Enter your graduation year" className="form" onChange={(e) => setYear(e.target.value)} />
                                    {yearError && <p className="error-message">{yearError}</p>}
                                </div>

                                <div className="number">
                                    <p>Phone number<sup style={{ color: "red" }}>*</sup></p>
                                    <input type="number" value={phone} placeholder="Enter your phone number" className="form" onChange={(e) => setPhone(e.target.value)} />
                                    {phoneError && <p className="error-message">{phoneError}</p>}
                                </div>

                                <div className="button">
                                    <button type="submit" className="login-btn">Register</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Workshopform;
