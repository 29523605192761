import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './ProjectSlider.scss';

import frontimg from '../asset/icons/frontimg.png';
import food from '../asset/icons/food.png';
import pick from '../asset/icons/pick.png';
import ecommerse from '../asset/icons/ecommerse.png';
import portfolio from '../asset/icons/portfolio.png';
import story from '../asset/icons/story.png';

const employees = [
  { id: 1, image: portfolio, name: 'Apna Portfolio', about: 'React Project', type: '(Major Project)' },
  { id: 2, image: food, name: 'Online food ordering app', about: 'React + firebase Project', type: '(Major Project)' },
  { id: 3, image: ecommerse, name: 'E-commerce Website', about: 'Mega React Project', type: '(Major Project)' },
  { id: 4, image: pick, name: 'Pick and Drop services website', about: 'Html and SCSS project', type: '(Minor Project)' },
  { id: 5, image: story, name: 'Story Book', about: 'HTML + CSS + Javascript project', type: '(Minor Project)' },
];

export default function ProjectSlider() {
  const [isPaused, setIsPaused] = useState(false);

  // Handler to pause the slider on click and resume after 3 seconds
  const handleCardClick = () => {
    setIsPaused(true); // Pause the slider
    setTimeout(() => {
      setIsPaused(false); // Resume the slider after 3 seconds
    }, 3000);
  };

  return (
    <div className="slider-container">
      <motion.div
        className="slider-row"
        initial={{ x: 0 }}
        animate={{ x: isPaused ? undefined : -employees.length * 300 }}
        transition={{
          repeat: Infinity,
          duration: employees.length * 10, // Further slowed down by increasing duration
          ease: 'linear',
          repeatDelay: isPaused ? 3 : 0, // Pause if `isPaused` is true
        }}
        onAnimationComplete={(definition) => {
          // Reset to start after each full loop
          if (!isPaused) definition.x = 0;
        }}
      >
        {[...employees, ...employees].map((employee, index) => (
          <motion.div
            key={index}
            className="employee-card"
            onClick={handleCardClick}
          >
            <img className="slide-image" src={employee.image} alt={employee.name} />
            <h2>{employee.name}</h2>
            <h4>{employee.about}</h4>
            <p>{employee.type}</p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}